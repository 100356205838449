import {
  Box,
  Heading as GrommetHeading,
  Paragraph,
  ResponsiveContext
} from 'grommet'
import { DirectionType } from 'grommet/utils'
import Image from 'next/image'
import Link from 'next/link'
import instagram from 'public/icons/instagram.svg'
import { useContext } from 'react'

const Contact = () => {
  const size = useContext(ResponsiveContext)
  const isSmall = size === 'small'
  const direction: DirectionType = isSmall ? 'column' : 'row'
  const textAlign = isSmall ? 'center' : 'start'
  const align = isSmall ? 'center' : 'start'
  return (
    <Box
      id="yhteystiedot"
      background="#2348B2"
      pad={{ top: 'xlarge', bottom: 'large', right: 'large', left: 'large' }}
    >
      <Box direction={direction} justify="between" align="center">
        {isSmall ? (
          <Box margin="xlarge" pad={{ bottom: 'large' }}>
            <Logo />
          </Box>
        ) : null}
        <GrommetHeading
          level="2"
          margin={{ bottom: 'large' }}
          color="white"
          textAlign="center"
        >
          Yhteys&shy;tiedot
        </GrommetHeading>
        {isSmall ? null : <Logo />}
      </Box>

      <Paragraph margin="small" alignSelf={align} textAlign={textAlign}>
        Botski sijaitsee Hakaniemessä, Eläintarhanlahdella osoitteessa
        Eläintarhantie 10, Helsinki
      </Paragraph>
      <Paragraph
        margin={{ bottom: 'large', horizontal: 'small', top: 'medium' }}
        alignSelf={align}
        textAlign={textAlign}
      >
        Ota yhteyttä sähköpostilla{' '}
        <span style={{ textDecoration: 'underline' }}>moi@stadinbotski.fi</span>
      </Paragraph>

      <Box margin={{ bottom: 'large' }}>
        <iframe
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1984.0400613888462!2d24.94006447735383!3d60.180069142634714!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x46920b3846a3a91b%3A0x6e50258cb3bb0c18!2sStadin%20Botski!5e0!3m2!1sen!2sch!4v1743285777287!5m2!1sen!2sch"
          width="100%"
          height="600"
          allowFullScreen
          loading="lazy"
          referrerPolicy="no-referrer-when-downgrade"
        />
      </Box>

      <Box align="center" margin={{ vertical: 'large' }}>
        <Link
          href="https://www.instagram.com/stadinbotski/"
          passHref
          target="_blank"
          rel="noopener noreferrer"
        >
          <Image width={75} height={75} src={instagram} alt="" />
        </Link>
      </Box>
    </Box>
  )
}
export default Contact

const Logo = () => (
  <Image
    src="/images/logo-white.svg"
    width={218.33}
    height={93.62}
    alt="logo"
  />
)
