import Header from 'components/Header'
import Contact from 'components/home/contact'
import Instructions from 'components/home/instructions'
import {
  Box,
  Button,
  Heading as GrommetHeading,
  Paragraph,
  ResponsiveContext,
  Text
} from 'grommet'
import { DirectionType } from 'grommet/utils'
import { useWindowSize } from 'hooks/useWindowSize'
import Image from 'next/legacy/image'
import Link from 'next/link'
import boatImg from 'public/images/boat.jpg'
import bgImgLarge from 'public/images/hero-large.jpg'
import bgImgSmall from 'public/images/hero.jpg'
import { useContext } from 'react'
import styled from 'styled-components'
import { BookingDuration } from 'types/types'
import { blueText, brandBlue } from 'utils/colors'
import { durationToPrice, getTitle } from 'utils/utils'

const Heading = styled(GrommetHeading)`
  font-family: Ultra;
  -webkit-font-smoothing: antialiased; // this makes Ultra font sharper
  font-weight: normal;
  line-height: 40px;
  text-shadow: 0px 1px 6px rgba(0, 0, 0, 0.5);
`

export const BookButton = styled(Button)`
  background: ${brandBlue};
  font-family: Ultra;
  -webkit-font-smoothing: antialiased; // this makes Ultra font sharper
  font-size: 20px;
  color: white;
  margin-left: auto;
  margin-right: auto;
  border-radius: 50px;
  box-shadow: 0px 2px 9px rgba(0, 0, 0, 0.5);
`

const BookButtonInverse = styled(BookButton)`
  background: ${brandBlue};
  color: white;
`

export default function Home() {
  const size = useContext(ResponsiveContext)
  const isSmall = size === 'small'
  const minHeight = isSmall ? '700px' : '900px'
  const bgImg = isSmall ? bgImgSmall : bgImgLarge
  const { width } = useWindowSize()
  const useSmallerFont = width !== undefined && width > 768 && width < 900 // hero copy would collide with button otherwise
  return (
    <>
      <Header />
      <Box>
        <Box
          align="center"
          style={{
            position: 'relative'
          }}
        >
          <Box
            style={{
              zIndex: -1,
              minHeight,
              width: '100vw',
              position: 'relative',
              display: 'block'
            }}
          >
            <Image
              layout="fill"
              objectFit="cover"
              src={bgImg}
              placeholder="blur"
            />
          </Box>
          {isSmall ? (
            <Box
              style={{ position: 'absolute' }}
              margin={{ top: 'xlarge', horizontal: 'medium' }}
            >
              <Heading
                level="3"
                margin={{ top: 'xlarge' }}
                color="white"
                textAlign="center"
                style={{
                  fontSize: isSmall ? '25px' : '40px'
                }}
              >
                Vuokraa <wbr />
                helppokäyttöinen sähkövene ja koe merellinen Helsinki.
              </Heading>
              <Link href="/vuokraa" legacyBehavior>
                <BookButton
                  margin={{
                    top: 'large',
                    bottom: 'xlarge',
                    right: 'small',
                    left: 'small'
                  }}
                  style={{
                    fontSize: isSmall ? '20px' : '30px',
                    padding: isSmall ? '20px 40px' : '30px 50px'
                  }}
                  primary
                  color={brandBlue}
                  label="Vuokraa vene"
                />
              </Link>
            </Box>
          ) : (
            <Box
              style={{
                position: 'absolute',
                bottom: 0
              }}
              pad={{
                horizontal: useSmallerFont ? '20px' : '5%',
                vertical: '25px'
              }}
              direction="row"
              justify="between"
              align="center"
              width="100%"
              background="rgba(0, 0, 0, 0.2)"
            >
              <Heading
                level="3"
                color="white"
                textAlign="start"
                style={{
                  fontSize: useSmallerFont ? '30px' : '40px',
                  width: '400px'
                }}
              >
                Vuokraa helppokäyttöinen sähkövene ja koe merellinen Helsinki.
              </Heading>

              <Link href="/vuokraa" legacyBehavior>
                <Box>
                  <BookButton
                    margin={{
                      right: 'small',
                      left: 'small'
                    }}
                    style={{
                      fontSize: '30px',
                      padding: '30px 40px'
                    }}
                    primary
                    color={brandBlue}
                    label="Vuokraa vene"
                  />
                </Box>
              </Link>
            </Box>
          )}
        </Box>
      </Box>
      <Box>
        <Boat />
        <Pricing />
        <FullDayBooking />
        <Instructions />
        <Contact />
      </Box>
      <footer />
    </>
  )
}

const Boat = () => {
  const size = useContext(ResponsiveContext)
  const isSmall = size === 'small'
  const direction: DirectionType = isSmall ? 'column' : 'row'
  return (
    <Box
      id="vene"
      background="#F6FBFF"
      pad={{ top: 'xlarge', bottom: 'large', horizontal: 'large' }}
      direction={direction}
    >
      <RoundImage isSmall={isSmall}>
        <Image height={420} src={boatImg} width={420} objectFit="cover" />
      </RoundImage>
      <Box margin={isSmall ? {} : { left: 'large' }} width="large">
        <GrommetHeading
          level="2"
          alignSelf={isSmall ? 'center' : 'start'}
          margin={isSmall ? { top: 'medium', bottom: 'medium' } : {}}
          color={blueText}
        >
          Vuokraa vene
        </GrommetHeading>
        <Paragraph margin={{ vertical: 'small' }} fill>
          Stadin Botski on helppo tapa nauttia kesästä ja merestä ystävien tai
          perheen seurassa. Reilu 5-metristä vuokravenettä voi ajaa
          jokamiehenoikeudella kaikki yli 18-vuotiaat. Et siis tarvitse ajo- tai
          veneilykorttia. Kyytiin mahtuu 8 hyvää ystävää, perheenjäsentä tai
          koiraa.
        </Paragraph>
        <Paragraph margin={{ vertical: 'small' }} fill>
          Vene on suunniteltu piknik-veneeksi Helsinkiin. Keskiössä on iso
          piknikpöytä, jonka äärellä on helppo jutella, nauttia virvokkeita ja
          pientä purtavaa. Pehmustetut ja mukavat istuimet takaavat
          takamuksellesi pehmeän kyydin. Venettä ohjataan pöydän päässä olevalla
          ruorilla.
        </Paragraph>
        <Paragraph margin={{ vertical: 'small' }} fill>
          Vene kulkee sähköllä, eli lähes äänettömän moottorin ääni ei häiritse
          tunnelmaa tai hyviä keskusteluita merellä, eikä myöskään saastuta
          Itämerta 💚. Veneen vauhti on hyvin rauhallinen ja se kulkee
          hölkkävauhtia. Toivottavasti sinulla ei ole kiire minnekään. Nauti
          merestä, laineiden liplatuksesta ja kiireettömästä tunnelmasta. Stadin
          Botskissa olet jo perillä.
        </Paragraph>
        <Paragraph margin={{ vertical: 'small' }} fill>
          Lähtö- ja paluusatama on Hakaniemessä Eläintarhanlahdella
          (Eläintarhantie 10, Helsinki).
        </Paragraph>
      </Box>
    </Box>
  )
}

const FullDayBooking = () => {
  const size = useContext(ResponsiveContext)
  const isSmall = size === 'small'
  const direction: DirectionType = isSmall ? 'column' : 'row'
  const imgSrc = '/images/kippis.jpeg'
  const image = (
    <Image
      width={1536}
      height={1536}
      src={imgSrc}
      layout="responsive"
      style={{ borderRadius: '20px' }}
    />
  )
  return (
    <Box
      id="full-day-booking"
      pad={isSmall ? '' : 'large'}
      background="#F6FBFF"
      direction={direction}
    >
      {!isSmall ? null : (
        <>
          <Box
            width="100%"
            margin={{ bottom: 'large', horizontal: 'auto' }}
            style={{ maxWidth: '440px' }}
          >
            {image}
          </Box>
          <GrommetHeading
            level="2"
            textAlign={isSmall ? 'center' : 'start'}
            margin={{ vertical: 'large' }}
            color={blueText}
          >
            Vuokraa vene koko päiväksi
          </GrommetHeading>
        </>
      )}
      {isSmall ? null : (
        <Box
          width="50%"
          margin={isSmall ? { vertical: 'large', horizontal: 'auto' } : {}}
          style={{ maxWidth: '440px' }}
        >
          {image}
        </Box>
      )}
      <Box margin={isSmall ? {} : { left: 'large' }} width="large">
        <Box direction="row">
          {isSmall ? null : (
            <GrommetHeading color={blueText}>
              Vuokraa vene koko päiväksi
            </GrommetHeading>
          )}
        </Box>
        <Box pad={isSmall ? 'large' : ''}>
          <Paragraph margin={{ vertical: 'small' }} fill>
            Jos haluat viettää päivän merellä pitkän kaavan kautta, niin
            suosittelemme vuokraamaan veneen koko päiväksi. Koko päivän vuokra
            on 300 €.
          </Paragraph>
          <Paragraph margin={{ vertical: 'small' }} fill>
            Koko päivän varauksen voit tehdä sähköpostilla{' '}
            <span style={{ textDecoration: 'underline' }}>
              moi@stadinbotski.fi
            </span>
          </Paragraph>
        </Box>
      </Box>
    </Box>
  )
}

const PricingSection = ({
  title,
  duration,
  text,
  price
}: {
  title: string
  duration: string
  text: string
  price: number
}) => {
  const size = useContext(ResponsiveContext)
  const isSmall = size === 'small'
  return (
    <Box direction="column">
      <Text
        color={blueText}
        size="large"
        weight="bold"
        margin={{ vertical: 'large' }}
      >
        {title}
      </Text>
      {isSmall ? null : (
        <Text margin={{ bottom: 'small' }} weight="bold">
          {duration}
        </Text>
      )}
      <Paragraph color="gray">{text}</Paragraph>
      <Text
        size="xxlarge"
        color={blueText}
        weight="bold"
        style={{ fontFamily: 'Ultra' }}
        margin={{ top: 'medium' }}
      >
        {isSmall ? `${duration} / ${price} €` : `${price} €`}
      </Text>
      {isSmall ? <Wave /> : null}
    </Box>
  )
}

const Pricing = () => {
  const size = useContext(ResponsiveContext)
  const isSmall = size === 'small'
  const direction: DirectionType = size === 'small' ? 'column' : 'row'
  return (
    <Box id="hinnasto" pad="large">
      <GrommetHeading
        level="2"
        color={blueText}
        alignSelf={isSmall ? 'center' : 'start'}
        margin={{ top: 'xlarge' }}
      >
        Hinnasto
      </GrommetHeading>
      <Box direction={direction} gap="medium">
        <PricingSection
          title={getTitle(BookingDuration.one)}
          duration="1 h"
          text="Kiva lyhyt pyrähdys merellä. Ehditte ajaa Pitkänsillan ali, nähdä Hakaniemeä ja Kalasatamaa esimerkiksi."
          price={durationToPrice(BookingDuration.one)}
        />
        <PricingSection
          title={getTitle(BookingDuration.two)}
          duration="2 h"
          text="Riittävästi aikaa tutustua merelliseen Helsinkiin kätevästi itseohjattavalla sähköveneellä. Vuokraveneeseen mahtuu enintään 8 henkilöä."
          price={durationToPrice(BookingDuration.two)}
        />
        <PricingSection
          title={getTitle(BookingDuration.three)}
          duration="3 h"
          text="Vene kulkee rauhallista 7 km/h vauhtia ja 3 tunnissa ehdit käydä esimerkiksi Mustikkamaalla, Herttoniemen rannassa, seilata Kulosaaren ympäri ja aikaa jää myös uintiin ja hengailuun."
          price={durationToPrice(BookingDuration.three)}
        />
        <PricingSection
          title={getTitle(BookingDuration.four)}
          duration="4 h"
          text="Sinulla on ruhtinaallisesti aikaa nauttia veneilystä. Voit pysähtyä ravintolaan syömään tai viettää kiireettömän merellisen piknikin. Veneen akku kestää noin 50 km ajon yhdellä latauksella. Vuokraa vene ja koe merellinen Helsinki."
          price={durationToPrice(BookingDuration.four)}
        />
      </Box>
      <Box align="center">
        <Link href="/vuokraa" legacyBehavior>
          <BookButtonInverse
            margin="large"
            size="large"
            primary
            color={brandBlue}
            label="Vuokraa vene"
          />
        </Link>
      </Box>
    </Box>
  )
}

const RoundImage = styled.div<{ isSmall: boolean }>`
  margin: ${p => (p.isSmall ? '20px auto' : 'none')};
  img {
    border-radius: 50%;
  }
`

const Wave = styled.div`
  height: 13px;
  width: 100%;
  margin-top: 50px;
  background-image: url('/images/wave.svg');
  background-repeat: repeat-x;
`
